.root {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  padding-left: 1.8125rem;
  padding-right: 1.8125rem;
}

.wrap {
  display: flex;
  position: relative;
  z-index: 1;
  min-height: 200px;
  justify-content: center;
  align-items: center;
  width: 33.333333%;
}

.box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 11.25rem;
  height: 11.25rem;
  z-index: -1;
}

.box:after {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: var(--green);
  border-radius: 100000px;
  box-shadow: 0 0 8px rgba(102,51,153,0.2);
  animation: expand 3.25s cubic-bezier(0.455,0.03,0.515,0.955) -.4s infinite;
}

.box:before {
  content: " ";
  position: absolute;
  display: block;
  width: 300%;
  height: 300%;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -100%;
  border-radius: 100000px;
  animation: blast 3.25s cubic-bezier(0.215,0.61,0.355,1) infinite;
}

.sq {
  max-width: 2.9rem;
  margin: 0px;
  align-self: center;
  display: block;
  width: 52px;
  height: 52px;
}

.under {
  height: 0;
  width: 100%;
  padding-bottom: 100%;
  transform: translateY(-50%) translateX(-50%);
  z-index: -2;
  left: 50%;
  top: 50%;
  border: 1px solid #f5f3f7;
  border-radius: 10000px;
  position: absolute;
  background: var(--green);
}

@keyframes blast {
  0% {
    transform: scale(0);
  }
  10% {
    transform: scale(.33);
    opacity: 0.75;
    background-color: var(--green);
  }
  80% {
    opacity: 0;
  }
}

@keyframes expand {
  0% {
    transform: scale(.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(.8);
  }
}
