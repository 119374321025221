.root {

}

.header {
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  left: auto;
}

.wrap {
  position: relative;
  width: 100%;
  height: 100px;
}

.overlay {
  position: absolute;
  background-color: var(--bg-main);
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0.4;
  top: 0;
  left: 0;
}

.hide {
  width: 0;
  height: 0;
  z-index: 0;
}