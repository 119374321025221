.root {
  position: absolute;
  z-index: 10;
  width: 25px;
  height: 25px;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .root {
    right: 55px;
    top: 55px;
  }
}

.bar {
  display: block;
  position: absolute;
  background-color: #fff;
  transition: all .2s ease-out;
  border: none;
  outline: none;
  font-size: 0;
  line-height: 0;
}

.bar:first-child {
  top: 5px;
  width: 25px;
  height: 3px;
}

.open .bar:first-child {
  top: 11px;
  transform: rotate(-135deg);
}

.bar:nth-child(2) {
  top: 11px;
  width: 25px;
  height: 3px;
}

.open .bar:nth-child(2) {
  top: 11px;
  transform: rotate(135deg);
}
