.root {
  position: fixed;
  width: 100%;
  background-color: rgba(25, 25, 25, 0.95);
  top: 0px;
  right: 0px;
  bottom: 0px;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
}

@media (min-width: 667px) {
  .root {
    width: 500px;
  }
}

@media (min-width: 1024px) {
  .root {
    width: 600px;
  }
}

.root.open {
  transform: translateX(0);
}

.ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding-left: 0;
}

@media (min-width: 667px) {
  .ul {
    width: 300px;
  }
}

@media (min-width: 1024px) {
  .ul {
    width: 430px;
  }
}

.li {
  display: block;
  width: 100%;
  list-style: none;
  text-align: center;
}

@media (min-width: 667px) {
  .li {
    text-align: left;
  }
}

@media (min-width: 1024px) {
  .li {
    margin: 10px 0;
  }
}

.link {
  position: relative;
  font-size: 45px;
  line-height: 55px;
  text-transform: uppercase;
  letter-spacing: -0.025em;
  text-align: center;
  color: var(--tx-main);
  text-decoration: none;
  font-family: var(--font-heavy);
  transition: color 150ms ease-in-out;
}

.link:hover {
  color: var(--tx-dark);
}

@media (min-width: 667px) {
  .link {
    text-align: left;
    font-size: 60px;
    line-height: 75px;
  }
}

@media (min-width: 1024px) {
  .link {
    font-size: 70px;
    line-height: 70px;
    padding: 0 15px;
  }

  .link:after {
    position: absolute;
    content: '';
    top: 10px;
    bottom: 0px;
    left: 0px;
    width: 0%;
    background-color: rgba(95, 215, 255, 0.15);
    transition: width .3s ease-out;
  }

  .link:hover:after {
    width: 104%;
  }
}
