.root {
  display: block;
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background-position-x: 70%;
  background-position-y: -70%;
  background-repeat: no-repeat;
  background-color: var(--bg-main);
}

.overlay {
  display: flex;
  height: 100%;
  z-index: 1;
  justify-content: center;
  align-items: center;
}

.video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
}